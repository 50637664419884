import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import shortid from 'shortid'

import { Box } from 'components/Grid'
import { Section } from 'components/Section'
import Carousel from 'components/Carousel'

const GalleryTemplate = ({ sectionTitle, data }) => {
  return (
    <Section title={sectionTitle} my={[5, 5, 6]}>
      <Box width={1}>
        <Carousel dots fade arrows={false}>
          {data.map(item => (
            <Img
              key={shortid.generate()}
              fluid={item.image.childImageSharp.fluid}
              alt="Image from Famillathlon event"
            />
          ))}
        </Carousel>
      </Box>
    </Section>
  )
}

GalleryTemplate.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
}

export const Gallery = ({ data, ...props }) => {
  if (!data) {
    return null
  }
  return <GalleryTemplate data={data} {...props} />
}

Gallery.propTypes = {
  data: PropTypes.array.isRequired,
}
