import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import shortid from 'shortid'

import CustomLink from 'components/CustomLink'
import { Flex, Box } from 'components/Grid'

const LogoGridTemplate = ({ data }) => (
  <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
    {data.map(logo => (
      <Box key={shortid.generate()} p={4}>
        <CustomLink linkURL={logo.linkURL} linkType="external">
          <Img
            fixed={logo.logo.childImageSharp.fixed}
            alt={logo.organization}
          />
        </CustomLink>
      </Box>
    ))}
  </Flex>
)

export const LogoGrid = ({ data }) => {
  if (!data) {
    return null
  }
  return <LogoGridTemplate data={data} />
}

LogoGrid.propTypes = {
  data: PropTypes.array.isRequired,
}

LogoGridTemplate.propTypes = LogoGrid.propTypes
