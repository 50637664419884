import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import { Hero } from 'components/Hero'
import { Section } from 'components/Section'
import { LogoGrid } from 'components/LogoGrid'
import { CallToAction } from 'components/CallToAction'
import { EventsRoll } from 'components/Events'
import { Gallery } from 'components/Gallery'

import styled, { themePx } from 'util/style'

const Subheading = styled.h2`
  margin-bottom: ${themePx('space.4')};
  font-size: ${themePx('fontSizes.1')};
  font-weight: 400;
  text-align: center;
  line-height: 33px;
`

export const FamillathlonPageTemplate = ({
  // title,
  hero,
  intro,
  miniEvents,
  gallery,
  organizers,
  goldenSponsors,
  sponsors,
  partners,
  volunteersCTA,
  facebookCTA,
  contactUsCTA,
}) => (
  <>
    <Hero data={hero} />
    <Section title={intro.title}>
      <Subheading>{intro.description}</Subheading>
    </Section>
    <Gallery sectionTitle="Снимки от събитието" data={gallery} />
    <EventsRoll
      sectionTitle="Не пропускайте"
      data={miniEvents}
      itemsToShow={4}
    />
    <CallToAction data={facebookCTA} />
    <CallToAction data={volunteersCTA} />
    <Section title="Организатори">
      <LogoGrid data={organizers} />
    </Section>
    <Section title="Партньори">
      <LogoGrid data={partners} />
    </Section>
    <Section title="Златни спонсори">
      <LogoGrid data={goldenSponsors} />
    </Section>
    <Section title="Спонсори">
      <LogoGrid data={sponsors} />
    </Section>
    <CallToAction data={contactUsCTA} />
  </>
)

FamillathlonPageTemplate.propTypes = {
  // title: PropTypes.string.isRequired,
  hero: PropTypes.object.isRequired,
  intro: PropTypes.object.isRequired,
  miniEvents: PropTypes.array.isRequired,
  gallery: PropTypes.array.isRequired,
  organizers: PropTypes.array.isRequired,
  goldenSponsors: PropTypes.array.isRequired,
  sponsors: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  volunteersCTA: PropTypes.object.isRequired,
  facebookCTA: PropTypes.object.isRequired,
  contactUsCTA: PropTypes.object.isRequired,
}

const FamillathlonPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { footerData = {}, headerData = {} } = data

  let hData = {}
  if (headerData.edges && headerData.edges.length > 0) {
    hData = headerData.edges[0].node.frontmatter
  }

  let fData = {}
  if (footerData.edges && footerData.edges.length > 0) {
    fData = footerData.edges[0].node.frontmatter
  }

  return (
    <Layout headerData={hData} footerData={fData}>
      <SEO title={frontmatter.title} />
      <FamillathlonPageTemplate
        title={frontmatter.title}
        hero={frontmatter.hero}
        intro={frontmatter.intro}
        miniEvents={frontmatter.miniEvents}
        gallery={frontmatter.gallery}
        organizers={frontmatter.organizers}
        goldenSponsors={frontmatter.goldenSponsors}
        sponsors={frontmatter.sponsors}
        partners={frontmatter.partners}
        volunteersCTA={frontmatter.callToActions.volunteers}
        facebookCTA={frontmatter.callToActions.facebook}
        contactUsCTA={frontmatter.callToActions.contactUs}
      />
    </Layout>
  )
}

FamillathlonPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }).isRequired,
}

export default FamillathlonPage

export const pageQuery = graphql`
  query FamillathlonPageTemplate {
    ...LayoutFragment
    markdownRemark(frontmatter: { templateKey: { eq: "famillathlon-page" } }) {
      frontmatter {
        title
        hero {
          image {
            childImageSharp {
              fluid(maxWidth: 850, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
                presentationHeight
              }
            }
          }
          imageAlt
          heading
          subheading
          action
          actionURL
          linkType
        }
        intro {
          title
          description
        }
        miniEvents {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 300, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          timeframe
          description
        }
        gallery {
          image {
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 300, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        callToActions {
          volunteers {
            heading
            subheading
            actionURL
            actionLabel
            linkType
          }
          facebook {
            heading
            subheading
            actionURL
            actionLabel
            linkType
          }
          contactUs {
            heading
            subheading
          }
        }
        organizers {
          organization
          logo {
            childImageSharp {
              fixed(height: 100, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
          linkURL
        }
        partners {
          organization
          logo {
            childImageSharp {
              fixed(width: 80, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
          linkURL
        }
        goldenSponsors {
          organization
          logo {
            childImageSharp {
              fixed(width: 120, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
          linkURL
        }
        sponsors {
          organization
          logo {
            childImageSharp {
              fixed(width: 100, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
          linkURL
        }
      }
    }
  }
`
