import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import '../../node_modules/slick-carousel/slick/slick.css'
import '../../node_modules/slick-carousel/slick/slick-theme.css'

const Carousel = ({
  children,
  dots,
  arrows,
  slidesToShow,
  slidesToScroll,
  fade,
  ...props
}) => {
  const settings = {
    autoplay: true,
    dots,
    arrows,
    infinite: true,
    speed: 500,
    fade,
    slidesToShow,
    slidesToScroll,
    pauseOnHover: false,
  }

  return (
    <>
      <Slider {...settings} {...props}>
        {children}
      </Slider>
    </>
  )
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  dots: PropTypes.bool,
  arrows: PropTypes.bool,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  fade: PropTypes.bool,
}

Carousel.defaultProps = {
  dots: true,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: false,
}

export default Carousel
