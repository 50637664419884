import React from 'react'
import PropTypes from 'prop-types'
import styled, { themePx, themeGet } from 'util/style'
import Img from 'gatsby-image'
import shortid from 'shortid'

import { Flex, Box } from 'components/Grid'
import { Section } from 'components/Section'
import { Title } from 'components/News/styles'

const StyledDate = styled(Box)`
  font-size: ${themePx('fontSizes.0')};
  font-weight: 600;
  color: ${themeGet('colors.lightGrey')};
  text-transform: uppercase;
`
const EventsRollTemplate = ({ sectionTitle, data, itemsToShow }) => {
  return (
    <Section title={sectionTitle} my={[5, 5, 6]}>
      <Flex flexWrap="wrap">
        {data.length > 0 &&
          data.slice(0, itemsToShow).map(event => {
            const { title, image, timeframe, description } = event
            return (
              <Box
                key={shortid.generate()}
                width={[1, 1 / 2, 1 / 2]}
                p={[0, 3, 4]}
                mb={[4, 0, 0]}
              >
                <Img fluid={image.childImageSharp.fluid} alt={title} />
                <Title my={2}>{title}</Title>
                <StyledDate>{timeframe}</StyledDate>
                <Box mt={2}>{description}</Box>
              </Box>
            )
          })}
      </Flex>
    </Section>
  )
}

EventsRollTemplate.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  itemsToShow: PropTypes.number,
}

EventsRollTemplate.defaultProps = {
  itemsToShow: 3,
}

export const EventsRoll = ({ data, ...props }) => {
  if (!data) {
    return null
  }
  return <EventsRollTemplate data={data} {...props} />
}

EventsRoll.propTypes = {
  data: PropTypes.array.isRequired,
  itemsToShow: PropTypes.number,
}

EventsRoll.defaultProps = {
  itemsToShow: 3,
}
